import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-west-valley-city-utah.png'
import image0 from "../../images/cities/scale-model-of-the-farm-at-gardner-village-in-west-valley-city-utah.png"
import image1 from "../../images/cities/scale-model-of-great-salt-lake-sightseeing-tours-in-west-valley-city-utah.png"
import image2 from "../../images/cities/scale-model-of-temple-square-in-west-valley-city-utah.png"
import image3 from "../../images/cities/scale-model-of-brigham-young-monument-in-west-valley-city-utah.png"
import image4 from "../../images/cities/scale-model-of-utah-parks-&-recreation-in-west-valley-city-utah.png"
import image5 from "../../images/cities/scale-model-of-spotlight-tours-in-west-valley-city-utah.png"
import image6 from "../../images/cities/scale-model-of-gray-line-in-west-valley-city-utah.png"
import image7 from "../../images/cities/scale-model-of-certified-folder-display-service,-inc.-in-west-valley-city-utah.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='West Valley City'
            state='Utah'
            image={image}
            lat='40.6885'
            lon='-112.0118'
            attractions={ [{"name": "The Farm at Gardner Village", "vicinity": "1100 W 7800 S, West Jordan", "types": ["point_of_interest", "establishment"], "lat": 40.6093584, "lng": -111.92436470000001}, {"name": "Great Salt Lake Sightseeing Tours", "vicinity": "230 W 200 S #4874, Salt Lake City", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.7659344, "lng": -111.8979443}, {"name": "Temple Square", "vicinity": "50 N Temple, Salt Lake City", "types": ["museum", "place_of_worship", "point_of_interest", "establishment"], "lat": 40.7707425, "lng": -111.89107089999999}, {"name": "Brigham Young Monument", "vicinity": "Salt Lake City", "types": ["point_of_interest", "establishment"], "lat": 40.7695776, "lng": -111.89110629999999}, {"name": "Utah Parks & Recreation", "vicinity": "1594 W North Temple St suite 116, Salt Lake City", "types": ["park", "point_of_interest", "establishment"], "lat": 40.7721957, "lng": -111.93665859999999}, {"name": "Spotlight Tours", "vicinity": "Salt Lake City", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.7563925, "lng": -111.8985922}, {"name": "Gray Line", "vicinity": "57 W 800 S, Salt Lake City", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.7517596, "lng": -111.89278179999997}, {"name": "Certified Folder Display Service, Inc.", "vicinity": "47 Orange St Suite A4, Salt Lake City", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.7683729, "lng": -111.94315599999999}] }
            attractionImages={ {"The Farm at Gardner Village":image0,"Great Salt Lake Sightseeing Tours":image1,"Temple Square":image2,"Brigham Young Monument":image3,"Utah Parks & Recreation":image4,"Spotlight Tours":image5,"Gray Line":image6,"Certified Folder Display Service, Inc.":image7,} }
       />);
  }
}